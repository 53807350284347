"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useDebouncedCallback_1 = require("./useDebouncedCallback");
function valueEquality(left, right) {
    return left === right;
}
function adjustFunctionValueOfSetState(value) {
    return typeof value === 'function' ? function () { return value; } : value;
}
function useStateIgnoreCallback(initialState) {
    var _a = react_1.useState(adjustFunctionValueOfSetState(initialState)), state = _a[0], setState = _a[1];
    var setStateIgnoreCallback = react_1.useCallback(function (value) { return setState(adjustFunctionValueOfSetState(value)); }, []);
    return [state, setStateIgnoreCallback];
}
function useDebounce(value, delay, options) {
    var eq = (options && options.equalityFn) || valueEquality;
    var _a = useStateIgnoreCallback(value), state = _a[0], dispatch = _a[1];
    var debounced = useDebouncedCallback_1.default(react_1.useCallback(function (value) { return dispatch(value); }, [dispatch]), delay, options);
    var previousValue = react_1.useRef(value);
    react_1.useEffect(function () {
        // We need to use this condition otherwise we will run debounce timer for the first render (including maxWait option)
        if (!eq(previousValue.current, value)) {
            debounced.callback(value);
            previousValue.current = value;
        }
    }, [value, debounced, eq]);
    return [state, { cancel: debounced.cancel, pending: debounced.pending, flush: debounced.flush }];
}
exports.default = useDebounce;
